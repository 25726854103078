// eslint-disable-next-line import/prefer-default-export
export function formatRupiah(value) {
  if (!value) return 'Rp 0'
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
}
