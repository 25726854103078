import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/crew',
      component: () => import('@/views/Index.vue'),
      children: [
        {
          path: 'supir',
          name: 'supir',
          component: () => import('@/views/Supir/Index.vue'),
          meta: {
            pageTitle: 'Supir',
            breadcrumb: [
              {
                text: 'Supir',
                active: true,
              },
            ],
          },
        },
        {
          path: 'kernet',
          name: 'kernet',
          component: () => import('@/views/Kernet/Index.vue'),
          meta: {
            pageTitle: 'Kernet',
            breadcrumb: [
              {
                text: 'Kernet',
                active: true,
              },
            ],
          },
        },
        {
          path: 'karyawan',
          name: 'karyawan',
          component: () => import('@/views/Karyawan/Index.vue'),
          meta: {
            pageTitle: 'Karyawan',
            breadcrumb: [
              {
                text: 'Karyawan',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/bus',
      component: () => import('@/views/Index.vue'),
      children: [
        {
          path: '/',
          name: 'bus',
          component: () => import('@/views/Bus/Index.vue'),
          meta: {
            pageTitle: 'Bus',
            breadcrumb: [
              {
                text: 'Bus',
                active: true,
              },
            ],
          },
        },
        {
          path: 'services/:id',
          name: 'bus-services',
          component: () => import('@/views/Bus/Service/Index.vue'),
          meta: {
            pageTitle: 'Services',
            breadcrumb: [
              {
                text: 'Services',
                active: true,
              },
            ],
          },
        },
        {
          path: 'assets/:id',
          name: 'bus-assets',
          component: () => import('@/views/Bus/Assets/Index.vue'),
          meta: {
            pageTitle: 'Assets',
            breadcrumb: [
              {
                text: 'Assets',
                active: true,
              },
            ],
          },
        },
        {
          path: 'suku-cadang/:id',
          name: 'bus-suku-cadang',
          component: () => import('@/views/Bus/Suku-Cadang/Index.vue'),
          meta: {
            pageTitle: 'Suku Cadang',
            breadcrumb: [
              {
                text: 'Suku Cadang',
                active: true,
              },
            ],
          },
        },
        {
          path: 'pajak/:id',
          name: 'bus-pajak',
          component: () => import('@/views/Bus/Pajak/Index.vue'),
          meta: {
            pageTitle: 'Pajak',
            breadcrumb: [
              {
                text: 'Pajak',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/booking',
      component: () => import('@/views/Index.vue'),
      children: [
        {
          path: '/',
          name: 'booking',
          component: () => import('@/views/Booking/Index.vue'),
          meta: {
            pageTitle: 'Booking',
            breadcrumb: [
              {
                text: 'Booking',
                active: true,
              },
            ],
          },
        },
        {
          path: 'add/:type',
          name: 'add-booking',
          component: () => import('@/views/Booking/Add.vue'),
          meta: {
            pageTitle: 'Tambah Booking',
            breadcrumb: [
              {
                text: 'Tambah Booking',
                active: true,
              },
            ],
          },
        },
        {
          path: 'edit/:id',
          name: 'edit-booking',
          component: () => import('@/views/Booking/Edit.vue'),
          meta: {
            pageTitle: 'Update Booking',
            breadcrumb: [
              {
                text: 'Update Booking',
                active: true,
              },
            ],
          },
        },
        {
          path: 'preview/:id',
          name: 'preview-booking',
          component: () => import('@/views/Booking/Preview.vue'),
        },
      ],
    },
    {
      path: '/keuangan',
      component: () => import('@/views/Index.vue'),
      children: [
        {
          path: '/',
          name: 'keuangan',
          component: () => import('@/views/Keuangan/Index.vue'),
          meta: {
            pageTitle: 'Keuangan',
            breadcrumb: [
              {
                text: 'Keuangan',
                active: true,
              },
            ],
          },
        },
        {
          path: '/kas',
          name: 'kas',
          component: () => import('@/views/Keuangan/Kas/Index.vue'),
          meta: {
            pageTitle: 'Data Kas Bis',
            breadcrumb: [
              {
                text: 'Kas',
                active: true,
              },
            ],
          },
        },
        {
          path: '/hutang-kru',
          name: 'hutang-kru',
          component: () => import('@/views/Keuangan/HutangSupir/Index.vue'),
          meta: {
            pageTitle: 'Data Hutang Supir',
            breadcrumb: [
              {
                text: 'Hutang Supir',
                active: true,
              },
            ],
          },
        },
        {
          path: '/pembayaran-supir',
          name: 'pembayaran-supir',
          component: () => import('@/views/Keuangan/PembayaranSupir/Index.vue'),
          meta: {
            pageTitle: 'Data Pembayaran Supir',
            breadcrumb: [
              {
                text: 'Pembayaran Supir',
                active: true,
              },
            ],
          },
        },
        {
          path: '/gaji-karyawan',
          name: 'gaji-karyawan',
          component: () => import('@/views/Keuangan/GajiKaryawan/Index.vue'),
          meta: {
            pageTitle: 'Data Gaji Karyawan',
            breadcrumb: [
              {
                text: 'Gaji Karyawan',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  // ...
  // explicitly return false to cancel the navigation
  const token = localStorage.getItem('token') ?? null
  if (to.name !== 'login' && !token) {
    next({ path: '/login' })
  }
  next()
})

export default router
